import React from 'react';
import { ReactCompareSlider, ReactCompareSliderHandle } from 'react-compare-slider';

import styled from 'styled-components';

import RmSnippet from '../codeSnippets/rmSnippet';
import HtmlSnippet from '../codeSnippets/htmlSnippet';

const Container = styled.div`
  display: none;
  width: 100%;
  height: calc(100vh - 80px);
	background: #86c232;

	@media screen and (max-width: 700px) {
		display: block;
  }
`;

const Handler = styled.span`
  position: absolute;
  height: 44px;
  width: 44px;
  left: 50%;
  top: 50%;
  margin-left: -22px;
  margin-top: -22px;
  border-radius: 50%;
  color: #fff;
  background-color: #86c232;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  letter-spacing: 0.1em;
  font-weight: 600;
  cursor: move;
  z-index: 4;
  box-shadow: 0 0 0 6px rgb(0 0 0 / 20%), 0 0 10px rgb(0 0 0 / 60%),
    inset 0 1px 0 rgb(255 255 255 / 30%);
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
  transition: transform 0.3s 0.7s, opacity 0s 0.7s;
`;

const Description = styled.div`
	width: 100%;
	color: #fff;
	padding: 10px 0;
  text-align: center;
	user-select: none;
	
	p {
		margin-bottom: 0;
	}
`;

const Title = styled.span`
	font-size: 20px;
	font-weight: 500;
`;

const MyCustomHandle = () => {
  return (
		<div style={{ position: 'relative', width: '100%', height: '3px', backgroundColor: '#86c232' }}>
			<Handler>VS</Handler>
		</div>
	);
}


const MobileSlitView = () => {
  return (
    <Container>
			<Description>
				<Title>The react-mailkit code.</Title>
				<p>This is how you can create a button with react-mailkit.</p>
			</Description>
      <ReactCompareSlider
        onlyHandleDraggable={true}
        portrait={true}
        itemOne={<RmSnippet numOfLines={25} width='1rem' height="calc(100vh - 250px)" position='relative' />}
        itemTwo={<HtmlSnippet numOfLines={25} width='1rem' height="calc(100vh - 250px)" position='relative' />}
				handle={<MyCustomHandle/>}
      />
				<Description>
					<Title>The original html code.</Title>
					<p>This is how you should create a button with pure html.</p>
				</Description>
    </Container>
  );
};

export default MobileSlitView;
