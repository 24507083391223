import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  min-width: 400px;
  margin: 0 auto;
  border-bottom: 4px solid #86c232;
  overflow: hidden;
  transition: border 250ms ease;
  display: none;

  @media screen and (max-width: 800px) {
    display: block;
  }
`;

const List = styled.ul`
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  float: left;
  padding-left: 48px;
  list-style-type: none;
  color: #fff;
  text-shadow: rgba(0, 0, 0, 0.1) 0 1px;

  * {
    margin: 0px;
    padding: 0px;
  }
`;

type IItemProps = {
  isActive: boolean;
};

const Item = styled.li<IItemProps>`
  position: relative;
  display: block;
  float: right;
  padding: 10px 24px 8px;
  margin-right: 46px;
  z-index: ${props => (props.isActive ? 3 : 2)};
  cursor: pointer;
  text-shadow: rgba(0, 0, 0, 0.1) 0 1px;
  background-color: ${props => (props.isActive ? '#86c232' : '#fff')};
  color: ${props => (props.isActive ? '#fff' : '#86c232')};
  border-radius: 8px 8px 0 0;
  text-transform: uppercase;
  font: 600 13px/20px roboto, 'Open Sans', Helvetica, sans-serif;
  transition: all 250ms ease;

  &:before,
  &:after {
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    height: 100%;
    width: 44px;
    background-color: ${props => (props.isActive ? '#86c232' : '#fff')};
    color: ${props => (props.isActive ? '#fff' : '#86c232')};
    transition: all 250ms ease;
  }

  &:before {
    right: -24px;
    border-radius: 0 8px 0 0;
    transform: skew(30deg, 0deg);
    box-shadow: rgba(0, 0, 0, 0.1) 3px 2px 5px,
      inset rgba(255, 255, 255, 0.09) -1px 0;
  }

  &:after {
    left: -24px;
    border-radius: 8px 0 0 0;
    transform: skew(-30deg, 0deg);
    box-shadow: rgba(0, 0, 0, 0.1) -3px 2px 5px,
      inset rgba(255, 255, 255, 0.09) 1px 0;
  }

  &:hover {
    &:before,
    &:after {
      background-color: ${props => (props.isActive ? '#86c232' : '#f4f7f9')};
      color: ${props => (props.isActive ? '#fff' : '#86c232')};
    }
  }
`;

type IProps = {
  activeTab: string;
  onChange: (tab: string) => void;
};

const MobileSwitchTabs = (props: IProps) => {
  const { activeTab, onChange } = props;

  return (
    <Container>
      <List>
        <Item
          isActive={activeTab === 'preview'}
          onClick={() => onChange('preview')}
        >
          Preview
        </Item>
        <Item isActive={activeTab === 'live'} onClick={() => onChange('live')}>
          Try it live
        </Item>
      </List>
    </Container>
  );
};

export default MobileSwitchTabs;
