import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import map from 'lodash.map';
import camelCase from 'lodash.camelcase';
import upperfirst from 'lodash.upperfirst';
import useScrollPosition from '@react-hook/window-scroll';

import * as T from '../templates';

import TemplateCard from '../components/templateCard';
import Modal from '../components/modal';
import { useDocumentTitle } from '../hooks/useDocumentTitle';

const Title = styled.h2`
  color: white;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 0 auto;
  font-family: 'Rubik', sans-serif;
`;

function Templates() {
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const scrollY = useScrollPosition(60 /*fps*/);
  useDocumentTitle('Templates');

  useEffect(() => {
    if (scrollY > 50) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  const CompTag =
    selectedTemplate !== '' ? (
      T[upperfirst(camelCase(selectedTemplate))]
    ) : (
      <div>The Template wasn't found!</div>
    );

  return (
    <>
      <Title>Responsive Email Templates</Title>
      <Container>
        {map(T.templates, (t: string, index: number) => {
          const camelTemp = camelCase(t);

          return (
            <TemplateCard
              key={index}
              template={t === 'hAndm' ? T.hAndMMarkup : T[`${camelTemp}Markup`]}
              title={t === 'hAndm' ? 'H&M' : t}
              image={camelTemp}
              onPreviewClick={() => setSelectedTemplate(t)}
            />
          );
        })}
      </Container>
      {selectedTemplate !== '' && (
        <Modal
          isOpen={true}
          title={
            selectedTemplate === 'hAndm' ? 'H&M' : upperfirst(selectedTemplate)
          }
          content={<CompTag />}
          onClose={() => setSelectedTemplate('')}
        />
      )}
    </>
  );
}

export default Templates;
