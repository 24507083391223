import React, { useEffect } from 'react';

import styled from 'styled-components';

import RMSnippet from '../codeSnippets/rmSnippet';
import HtmlSnippet from '../codeSnippets/htmlSnippet';

const SplitViewContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 70px);
  min-height: 45vw;
  overflow: hidden;

  @media screen and (max-width: 700px) {
    display: none;
  }
`;

const TopPanel = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  min-height: 45vw;
  overflow: hidden;
  background-color: #86c232;
  z-index: 2;
  width: 50vw;
  transform: skew(-30deg);
  margin-left: -1000px;
  width: calc(50vw + 1000px);

  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

const TopContent = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  min-height: 45vw;
  color: #fff;
  transform: skew(30deg);
  margin-left: 1000px;
`;

const TopDescription = styled.div`
  width: 25%;
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  text-align: center;
  user-select: none;

  @media screen and (max-width: 1600px) {
    width: 100%;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);

    h1 {
      font-size: 1.5em;
    }

    p {
      font-size: 1em;
    }
  }
`;

const BottomPanel = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  min-height: 45vw;
  overflow: hidden;
  background-color: rgb(44, 44, 44);
  z-index: 1;
`;

const BottomContent = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  min-height: 45vw;
  color: #fff;
`;

const BottomDescription = styled.div`
  width: 25%;
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  text-align: center;
  user-select: none;

  @media screen and (max-width: 1600px) {
    width: 100%;
    top: unset;
    right: unset;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);

    h1 {
      font-size: 1.5em;
    }

    p {
      font-size: 1em;
    }
  }
`;

const Handle = styled.div`
  position: absolute;
  display: block;
  background-color: #222629;
  width: 5px;
  top: 0;
  left: 50%;
  z-index: 3;
  top: 50%;
  transform: rotate(30deg) translateY(-50%);
  height: 200%;
  transform-origin: top;
`;

const Handler = styled.span`
  position: absolute;
  height: 44px;
  width: 44px;
  left: 50%;
  top: 50%;
  margin-left: -22px;
  margin-top: -22px;
  border-radius: 50%;
  color: #fff;
  background-color: #86c232;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  letter-spacing: 0.1em;
  font-weight: 600;
  cursor: move;
  z-index: 4;
  box-shadow: 0 0 0 6px rgb(0 0 0 / 20%), 0 0 10px rgb(0 0 0 / 60%),
    inset 0 1px 0 rgb(255 255 255 / 30%);
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
  transition: transform 0.3s 0.7s, opacity 0s 0.7s;
`;

const SplitView = () => {
  useEffect(() => {
    handleAnimation();
  }, []);

  const handleAnimation = () => {
    let wrapper = document.getElementById('wrapper');
    let topLayer = document.getElementById('topPanel');
    let handle = document.getElementById('handler');
    let handler = document.getElementById('sc-handler');
    let skew = 1000;
    let delta = 0;

    wrapper!.addEventListener('mousemove', (e: any) => {
      // Get the delta between the mouse position and center point.
      delta = (e.clientX - window.innerWidth / 2) * 0.5;

      // Move the handle.
      handle!.style.left = e.clientX + delta + 'px';
      handler!.style.left = e.clientX + delta + 'px';

      // Adjust the top panel width.
      topLayer!.style.width = e.clientX + skew + delta + 'px';
    });
  };

  return (
    <SplitViewContainer id='wrapper'>
      <BottomPanel>
        <BottomContent>
          <BottomDescription>
            <h1>The original html code.</h1>
            <p>This is how you should create a button with pure html.</p>
          </BottomDescription>
          <HtmlSnippet />
        </BottomContent>
      </BottomPanel>
      <TopPanel id='topPanel'>
        <TopContent>
          <TopDescription>
            <h1>The react-mailkit code.</h1>
            <p>This is how you can create a button with react-mailkit.</p>
          </TopDescription>
          <RMSnippet />
        </TopContent>
      </TopPanel>
      <Handler id='sc-handler'>VS</Handler>
      <Handle id='handler' />
    </SplitViewContainer>
  );
};

export default SplitView;
