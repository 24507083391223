import React from 'react';
import styled from 'styled-components';

import Editor from './editor';

const Indent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Or = styled.span`
  padding: 10px 0;
`;

const Npm = styled.span`
  color: #a6e22e;
`;

const RMSnippet = () => {
  return (
    <Editor
      width='35%'
      height='150px'
      numOfLines={7}
      position='relative'
      code={
        <>
          <Npm>yarn add react-mailkit</Npm>
          <Indent>
            <Or>OR</Or>
          </Indent>
          <Npm>npm install react-mailkit</Npm>
        </>
      }
    />
  );
};

export default RMSnippet;
