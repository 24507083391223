import React from 'react';
import styled from 'styled-components';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faGithub,
  faLinkedinIn,
  faSlackHash
} from '@fortawesome/free-brands-svg-icons';
import { withTracker } from 'ga-4-react';

import Converter from './pages/converter';
import Templates from './pages/templates';
import TryItLive from './pages/tryItLive';
import Welcome from './pages/welcome';
import Header from './components/header';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
`;

const Main = styled.main`
  flex: 1;
  padding-top: 100px;
`;

const Footer = styled.footer`
  border-top: 1px solid rgba(248, 248, 252, 0.1);
`;

const SocialMediaContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SocialMediaList = styled.ul`
  display: flex;
  list-style: none;
  padding-left: 0;
  margin: 0;
`;

const SocialMediaItem = styled.li`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SocialMedia = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
`;

const Copyright = styled.p`
  text-align: center;
  color: #909399;
  font-size: 12px;
  font-family: Raleway, sans-serif;
  margin-top: 5px;
`;

const App = () => {
  return (
    <HashRouter>
      <Container>
        <Header />
        <Main>
          <Switch>
            <Route path='/templates' component={withTracker(Templates)} />
            <Route path='/converter' component={withTracker(Converter)} />
            <Route path='/try-it-live' component={withTracker(TryItLive)} />
            <Route path='/' component={withTracker(Welcome)} />
          </Switch>
        </Main>
        <Footer>
          <SocialMediaContainer>
            <SocialMediaList>
              <SocialMediaItem>
                <SocialMedia href='https://github.com/pavkout' target='_blank'>
                  <FontAwesomeIcon
                    icon={faGithub}
                    style={{ fontSize: '1.3rem', color: '#909399' }}
                  />
                </SocialMedia>
              </SocialMediaItem>
              <SocialMediaItem>
                <SocialMedia
                  href='https://www.linkedin.com/in/pavkout'
                  target='_blank'
                >
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    style={{ fontSize: '1.3rem', color: '#909399' }}
                  />
                </SocialMedia>
              </SocialMediaItem>
              <SocialMediaItem>
                <SocialMedia
                  href='https://www.facebook.com/pavkout'
                  target='_blank'
                >
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    style={{ fontSize: '1.3rem', color: '#909399' }}
                  />
                </SocialMedia>
              </SocialMediaItem>
              <SocialMediaItem>
                <SocialMedia
                  href='https://join.slack.com/t/react-mailkit/shared_invite/zt-vcxy3d37-khq0zTangdeflP2p6WUb5w'
                  target='_blank'
                >
                  <FontAwesomeIcon
                    icon={faSlackHash}
                    style={{ fontSize: '1.3rem', color: '#909399' }}
                  />
                </SocialMedia>
              </SocialMediaItem>
            </SocialMediaList>
          </SocialMediaContainer>
          <Copyright>
            Copyright &copy; 2021 react-mailkit.io <br /> Designed by Pavlos
            Koutoglou
          </Copyright>
        </Footer>
      </Container>
    </HashRouter>
  );
};

export default App;
