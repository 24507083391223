import React from 'react';
import styled from 'styled-components';

const GithubStat = styled.img`
  margin: 0 10px;

  @media screen and (max-width: 480px) {
    margin: 5px 0;
  }
`;

const GithubStats = () => {
  return (
    <>
      <a href='https://www.npmjs.com/package/react-mailkit' target='_blank'>
        <GithubStat
          alt='GitHub all releases'
          src='https://img.shields.io/npm/dm/react-mailkit?color=%238ac832'
        />
      </a>
      <a
        href='https://github.com/pavkout/react-mailkit/watchers'
        target='_blank'
      >
        <GithubStat
          alt='GitHub watchers'
          src='https://img.shields.io/github/watchers/pavkout/react-mailkit?color=%238ac832'
        />
      </a>
      <a
        href='https://github.com/pavkout/react-mailkit/stargazers'
        target='_blank'
      >
        <GithubStat
          alt='GitHub Repo stars'
          src='https://img.shields.io/github/stars/pavkout/react-mailkit?color=%238ac832'
        />
      </a>
      <a
        href='https://github.com/pavkout/react-mailkit/network/members'
        target='_blank'
      >
        <GithubStat
          alt='GitHub forks'
          src='https://img.shields.io/github/forks/pavkout/react-mailkit?color=%238ac832'
        />
      </a>
    </>
  );
};

export default GithubStats;
