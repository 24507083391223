import React from 'react';
import map from 'lodash.map';
import styled, { css } from 'styled-components';
import { useMediaQuery } from '@react-hook/media-query';

const font = css`
  font-family: 'Lucida Console', Monaco, monospace;
  font-size: 14px;
  user-select: none;
`;

type ICardProps = {
  width?: string;
  height?: string;
  position?: string;
};
const Card = styled.div<ICardProps>`
  ${font}

  width: ${props => props.width || '35%'};
  height: ${props => props.height || '820px'};
  perspective: 150rem;
  position: ${props => props.position || 'absolute'};
  top: ${props => (props.position === 'relative' ? 'unset' : '50%')};
  left: ${props => (props.position === 'relative' ? 'unset' : '50%')};
  transform: ${props =>
    props.position === 'relative' ? 'unset' : 'translate(-50%, -50%)'};

  @media screen and (max-width: 1600px) {
    width: 50%;
    height: ${props =>
      props.position === 'relative' ? props.height : '500px'};
  }

  @media screen and (max-width: 1100px) {
    width: 70%;
  }

  @media screen and (max-width: 700px) {
    width: calc(100% - 40px);
    margin: 10px 20px;
  }
`;

const CardInner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 0px #000000;
  transition: 0.9s cubic-bezier(0.25, 0.8, 0.25, 1);
  backface-visibility: hidden;
  overflow: hidden;
  background: linear-gradient(45deg, #101010, #2c3e50);

  &:before,
  &:after {
    position: absolute;
  }

  &:before {
    top: -70px;
    right: -70px;
    content: '';
    width: 140px;
    height: 140px;
    background-color: rgba(255, 255, 255, 0.08);
    transform: rotate(45deg);
    z-index: 1;
  }

  &:after {
    content: '';
    top: 10px;
    width: 40px;
    height: 40px;
    right: 10px;
    font-size: 24px;
    z-index: 2;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: url(logo-icon.png);
  }
`;

const LineNumbers = styled.div`
  ${font}
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 10px;
  background-color: rgba(255, 255, 255, 0.03);
  font-size: 13px;
  color: whitesmoke;

  > div {
    padding: 2.5px 0;
    opacity: 0.15;
  }
`;

const Code = styled.code`
  ${font}
  color: whitesmoke;
  padding: 5px 5px 5px 40px;

  @media screen and (max-width: 1600px) {
    font-size: 13px;
  }

  @media screen and (max-width: 700px) {
    font-size: 70%;
  }
`;

const Indent = styled.div`
  padding-left: 30px;
`;

const Operator = styled.span`
  color: #4dd0e1;
`;

const String = styled.span`
  color: #9ccc65;
`;

const Variable = styled.span`
  color: #ba68c8;
`;

const Property = styled.span`
  color: #ef5350;
`;

const Method = styled.span`
  color: #29b6f6;
`;

const Function = styled.span`
  color: #fdd835;
`;

const Boolean = styled.span`
  color: #4dd0e1;
`;

type IProps = {
  code: any;
  width?: string;
  height?: string;
  position?: string;
  numOfLines?: number;
};

const Editor = (props: IProps) => {
  const { code, width, height, position, numOfLines } = props;
  const matches = useMediaQuery('only screen and (max-width: 1600px)');

  let num = 36;

  if (matches) {
    num = 23;
  }

  if (numOfLines) {
    num = numOfLines;
  }

  return (
    <Card width={width} height={height} position={position}>
      <CardInner>
        <LineNumbers>
          {map(Array.from(Array(num).keys()), i => (
            <div key={i}>{i + 1}</div>
          ))}
        </LineNumbers>
        <Code>{code}</Code>
      </CardInner>
    </Card>
  );
};

export default Editor;
