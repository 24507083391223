import React, { useState } from 'react';
import { LiveProvider } from 'react-live';
import styled from 'styled-components';
import * as polished from 'polished';
import AceEditor from 'react-ace-builds';
import { useMediaQuery } from '@react-hook/media-query';

import Preview from './preview';
import ErrorHandler from './errorHandler';
import MobileSwitchTabs from './mobileSwitchTabs';

import 'react-ace-builds/webpack-resolver-min';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-twilight';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/ext-beautify';
import 'ace-builds/src-noconflict/ext-options';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-noconflict/snippets/javascript';
import 'ace-builds/src-noconflict/snippets/css';
import 'ace-builds/src-noconflict/ext-emmet';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 90px);
`;

const TryLiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

const StyledProvider = styled(LiveProvider)`
  box-shadow: 1px 1px 20px rgba(20, 20, 20, 0.27);
  overflow: hidden;
  margin-bottom: ${polished.rem(100)};
`;

type IProps = {
  noInline?: boolean;
  code: string;
  scope?: object;
  onChange?: (value: string) => void;
  validationLevel?: 'soft' | 'strict' | 'skip';
};

const LiveEdit = ({
  noInline = false,
  code,
  scope,
  onChange,
  validationLevel = 'soft'
}: IProps) => {
  const [mode, setMode] = useState('desktop');
  const [activeTab, setActiveTab] = useState('live');
  const matches = useMediaQuery('only screen and (max-width: 800px)');

  const previewCode = `
		Preview = () => {
			const { html } = useEmail(${code}, {
				validationLevel: '${validationLevel}',
			});

			return <div dangerouslySetInnerHTML={{ __html: html }} />
  	}`.trim();

  return (
    <Container>
      <MobileSwitchTabs activeTab={activeTab} onChange={setActiveTab} />
      <TryLiveContainer>
        <AceEditor
          mode='html'
          theme='twilight'
          name='liveEdit'
          onChange={onChange}
          fontSize={14}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          scrollMargin={[5, 0, 0, 0]}
          value={code}
          style={{
            width: matches && activeTab !== 'live' ? 0 : '100%',
            height: 'calc(100vh - 130px)'
          }}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2
          }}
        />
        <StyledProvider
          code={previewCode.trim()}
          noInline={noInline}
          scope={scope}
        >
          <Preview
            mode={mode}
            onChange={mode => setMode(mode)}
            hide={matches && activeTab === 'live'}
          />
          <ErrorHandler />
        </StyledProvider>
      </TryLiveContainer>
    </Container>
  );
};

export default LiveEdit;
