import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faMobileAlt } from '@fortawesome/free-solid-svg-icons';

type IButton = {
  isSelected: boolean;
};

const StyledPreviewButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 5px 5px 7px -2px rgba(0, 0, 0, 0.43);
  transition: box-shadow 0.3s ease-in-out;
`;

const StyledButton = styled.button<IButton>`
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  background-color: #86c232;
  color: white;
`;

type IProps = {
  mode: string;
  onChange: (mode: string) => void;
};

const SwitchButtons = ({ mode, onChange }: IProps) => {
  return (
    <StyledPreviewButton>
      <StyledButton
        onClick={() => onChange(mode === 'mobile' ? 'desktop' : 'mobile')}
      >
        <FontAwesomeIcon
          icon={mode === 'mobile' ? faDesktop : faMobileAlt}
          style={{ fontSize: '1.3rem' }}
        />
      </StyledButton>
    </StyledPreviewButton>
  );
};

export default SwitchButtons;
