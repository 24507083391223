export { default as Amario, amarioMarkup } from './amario';
export { default as Arturia, arturiaMarkup } from './arturia';
export { default as Austin, austinMarkup } from './austin';
export { default as BlackFriday, blackFridayMarkup } from './blackFriday';
export { default as Card, cardMarkup } from './card';
export { default as Christmas, christmasMarkup } from './christmas';
export { default as HAndm, hAndMMarkup } from './hAndm';
export { default as HappyNewYear, happyNewYearMarkup } from './happyNewYear';
export { default as OnePage, onePageMarkup } from './onePage';
export { default as Pizza, pizzaMarkup } from './pizza';
export { default as Proof, proofMarkup } from './proof';
export { default as Racoon, racoonMarkup } from './racoon';
export {
  default as ReactivationEmail,
  reactivationEmailMarkup
} from './reactivationEmail';
export { default as RealEstate, realEstateMarkup } from './realEstate';
export { default as Recast, recastMarkup } from './recast';
export { default as ReceiptEmail, receiptEmailMarkup } from './receiptEmail';
export { default as ReferralEmail, referralEmailMarkup } from './referralEmail';
export { default as TicketShop, ticketShopMarkup } from './ticketShop';
export { default as UggRoyale, uggRoyaleMarkup } from './uggRoyale';
export { default as WelcomeEmail, welcomeEmailMarkup } from './welcomeEmail';
export { default as Worldly, worldlyMarkup } from './worldly';

export const templates = [
  'Amario',
  'Arturia',
  'Austin',
  'Black Friday',
  'Card',
  'Christmas',
  'hAndm',
  'Happy New Year',
  'One Page',
  'Pizza',
  'Proof',
  'Racoon',
  'Reactivation Email',
  'Real Estate',
  'Recast',
  'Receipt Email',
  'Referral Email',
  'Ticket Shop',
  'Ugg Royale',
  'Welcome Email',
  'Worldly'
];
