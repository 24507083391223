import React from 'react';
import styled from 'styled-components';

import Editor from './editor';

const Indent = styled.div`
  padding-left: 30px;
`;

const Tag = styled.span`
  color: #f92672;
`;

const TagOperator = styled.span`
  color: #fff;
`;

const Props = styled.span`
  color: #a6e22e;
`;

const Property = styled.span`
  color: #e6db74;
`;

const Operator = styled.span`
  color: #f92672;
`;

type IProps = {
  width?: string;
  height?: string;
  position?: string;
  numOfLines?: number;
};

const RMSnippet = (props: IProps) => {
  return (
    <Editor
      width={props.width}
      height={props.height}
      position={props.position}
      numOfLines={props.numOfLines}
      code={
        <>
          <TagOperator>{`<`}</TagOperator>
          <Tag>{`EButton`}</Tag>
          <Props> backgroundColor</Props>
          <Operator>=</Operator>
          <Property>'#0547f2'</Property>
          <TagOperator>{`>`}</TagOperator>
          <Indent>
            <span>I'm a Button</span>
          </Indent>
          <TagOperator className='Pavlos'>{`</`}</TagOperator>
          <Tag>{`EButton`}</Tag>
          <TagOperator>{`>`}</TagOperator>
        </>
      }
    />
  );
};

export default RMSnippet;
