import React from 'react';
import ReactDOM from 'react-dom';
import GA4React from 'ga-4-react';

const ga4react = new GA4React('G-MXPQ8KPZQ8');

import App from './app';

(async () => {
  await ga4react.initialize();

  return ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
})();
