import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import useScrollPosition from '@react-hook/window-scroll';

const StyledHeader = styled.header`
  width: 100vw;
  display: flex;
`;

type ILogoProps = {
  isMinimized: boolean;
};

const Logo = styled.div<ILogoProps>`
  width: ${props => (props.isMinimized ? '170px' : '200px')};
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(logo.png);
  transition: 0.3s ease;
`;

type INavProps = {
  isMinimized: boolean;
};

const StyledNav = styled.nav<INavProps>`
  width: 100vw;
  height: ${props => (props.isMinimized ? '70px' : '100px')};
  box-shadow: ${props =>
    props.isMinimized ? '0 4px 30px -5px rgba(0, 0, 0, 0.2)' : 'none'};
  background-color: #222629;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  transition: all 0.3s;
`;

const styleWithFlex = css`
  display: flex;
  align-items: center;
`;

const BrandContainer = styled.div`
  ${styleWithFlex}

  padding-left: 40px;

  @media screen and (max-width: 480px) {
    padding-left: 20px;
  }
`;

const MenuContainer = styled.div`
  ${styleWithFlex}

  justify-content: flex-end;
`;

type IListProps = {
  isOpen: boolean;
  isMinimized: boolean;
};
const List = styled.ul<IListProps>`
  ${styleWithFlex}

  list-style: none;

  @media screen and (max-width: 1080px) {
    display: inline-block;
    width: 100vw;
    margin: 0;
    height: ${props => (props.isOpen ? '360px' : '0')};
    background: #fff;
    position: absolute;
    overflow: hidden;
    box-shadow: 0 5px 30px -4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    top: ${props => (props.isMinimized ? '70px' : '100px')};
  }
`;

type IListItemProps = {
  isOpen: boolean;
};

const ListItem = styled.li<IListItemProps>`
  margin-left: 20px;

  @media screen and (max-width: 1080px) {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    opacity: ${props => (props.isOpen ? '1' : '0')};
    visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
    margin-left: 0;
    transition: all 0.3s 0.1s;
  }

  @media screen and (max-width: 480px) {
    margin-left: 0px;
  }
`;

const StyledLink = styled(Link)`
  color: #86c232;
  text-decoration: none;
  font-size: 20px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 0.5em 1.5em;
  border-radius: 6px;
  background: transparent;
  font-weight: 600;
  margin-left: 8px;
  transition: opacity 0.62s;
  white-space: nowrap;

  &:hover {
    opacity: 0.6;
  }

  @media screen and (max-width: 1600px) {
    font-size: 17px;
  }

  @media screen and (max-width: 480px) {
    flex: 1;
    padding: 0;
    padding-left: 10px;
    width: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const StyledAnchor = styled.a`
  color: #86c232;
  text-decoration: none;
  font-size: 20px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 0.5em 1.5em;
  border-radius: 6px;
  background: transparent;
  font-weight: 600;
  margin-left: 8px;
  transition: opacity 0.62s;
  white-space: nowrap;

  &:hover {
    opacity: 0.6;
  }

  @media screen and (max-width: 1600px) {
    font-size: 17px;
  }

  @media screen and (max-width: 480px) {
    flex: 1;
    padding: 0;
    padding-left: 10px;
    width: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

type IMenuIconBarProps = {
  isOpen: boolean;
};

const MenuIconBar = styled.div<IMenuIconBarProps>`
  width: 25px;
  height: 2px;
  background: #86c232;
  transition: 0.3s ease-in-out;

  &:first-child {
    width: ${props => (props.isOpen ? '25px' : '20px')};
    transform: ${props => props.isOpen && 'translateY(7px) rotate(45deg)'};
  }

  &:nth-child(2) {
    margin: 5px 0;
    transform: ${props => props.isOpen && 'scale(0)'};
  }

  &:last-child {
    width: ${props => (props.isOpen ? '25px' : '15px')};
    transform: ${props => props.isOpen && 'translateY(-7px) rotate(-45deg)'};
  }
`;

type IMenuProps = {
  isOpen?: boolean;
};

const MenuToggle = styled.div<IMenuProps>`
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  padding-right: 40px;
  display: none;

  &:hover ${MenuIconBar} {
    width: 25px;
  }

  @media screen and (max-width: 1080px) {
    display: flex;
  }

  @media screen and (max-width: 480px) {
    padding-right: 0px;
  }
`;

const Header = () => {
  const scrollY = useScrollPosition(60 /*fps*/);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <StyledHeader>
      <StyledNav isMinimized={scrollY > 100}>
        <BrandContainer>
          <Logo isMinimized={scrollY > 100} />
        </BrandContainer>
        <MenuContainer onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <MenuToggle>
            <div>
              <MenuIconBar isOpen={isMenuOpen} />
              <MenuIconBar isOpen={isMenuOpen} />
              <MenuIconBar isOpen={isMenuOpen} />
            </div>
          </MenuToggle>
          <List isOpen={isMenuOpen} isMinimized={scrollY > 100}>
            <ListItem isOpen={isMenuOpen}>
              <StyledLink to='/'>Home</StyledLink>
            </ListItem>
            <ListItem isOpen={isMenuOpen}>
              <StyledLink to='/templates'>Templates</StyledLink>
            </ListItem>
            <ListItem isOpen={isMenuOpen}>
              <StyledLink to='/try-it-live'>Try It Live</StyledLink>
            </ListItem>
            <ListItem isOpen={isMenuOpen}>
              <StyledAnchor
                target='_blank'
                href='https://pavkout.github.io/react-mailkit/'
              >
                Docs
              </StyledAnchor>
            </ListItem>
            <ListItem isOpen={isMenuOpen}>
              <StyledAnchor
                target='_blank'
                href='https://github.com/pavkout/react-mailkit'
              >
                Github
              </StyledAnchor>
            </ListItem>
            <ListItem isOpen={isMenuOpen}>
              <StyledAnchor
                target='_blank'
                href='https://join.slack.com/t/react-mailkit/shared_invite/zt-vcxy3d37-khq0zTangdeflP2p6WUb5w'
              >
                Slack
              </StyledAnchor>
            </ListItem>
          </List>
        </MenuContainer>
      </StyledNav>
    </StyledHeader>
  );
};

export default Header;
