import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useScrollPosition from '@react-hook/window-scroll';
import { useDocumentTitle } from '../hooks/useDocumentTitle';

import {
  EAccordion,
  EAccordionElement,
  EAccordionText,
  EAccordionTitle,
  EAll,
  EAttributes,
  EBody,
  EBreakpoint,
  EButton,
  ECarousel,
  ECarouselImage,
  EClass,
  EColumn,
  EDivider,
  EFont,
  EGroup,
  EHead,
  EHero,
  EHtmlAttributes,
  EHtmlAttribute,
  EImage,
  EInclude,
  EMail,
  ENavbar,
  ENavbarLink,
  EPreview,
  ERaw,
  ESection,
  ESelector,
  ESocial,
  ESocialElement,
  ESpacer,
  EStyle,
  ETable,
  EText,
  ETitle,
  EWrapper,
  EAppStore,
  EComment,
  EImageText,
  ETracking,
  EQrCode,
  EYahooStyles,
  ESocialMedia,
  ESocialPost,
  EUnderlinedTitle,
  EMap,
  ERating,
  EAvatar,
  EYoutube,
  EYoutubeThumbnail,
  EBarcode,
  useEmail
} from 'react-mailkit';

import LiveEdit from '../components/liveEdit';

const initialMarkup = `<EMail>
<EBody backgroundColor="#fff">
  <ESection>
    <EColumn>
      <EText fontStyle="italic" fontSize="20px" color="#626262">
        Awesome Mail Kit
    </EText>
    </EColumn>
  </ESection>
</EBody>
</EMail>`;

const TryItLive = () => {
  const scope = {
    React,
    EAccordion,
    EAccordionElement,
    EAccordionText,
    EAccordionTitle,
    EAll,
    EAttributes,
    EBody,
    EBreakpoint,
    EButton,
    ECarousel,
    ECarouselImage,
    EClass,
    EColumn,
    EDivider,
    EFont,
    EGroup,
    EHead,
    EHero,
    EHtmlAttributes,
    EHtmlAttribute,
    EImage,
    EInclude,
    EMail,
    ENavbar,
    ENavbarLink,
    EPreview,
    ERaw,
    ESection,
    ESelector,
    ESocial,
    ESocialElement,
    ESpacer,
    EStyle,
    ETable,
    EText,
    ETitle,
    EWrapper,
    EAppStore,
    EComment,
    EImageText,
    EQrCode,
    EMap,
    ERating,
    EAvatar,
    ESocialPost,
    EUnderlinedTitle,
    EYoutube,
    EYoutubeThumbnail,
    EBarcode,
    ETracking,
    EYahooStyles,
    ESocialMedia,
    useEmail
  };

  const scrollY = useScrollPosition(60 /*fps*/);
  useDocumentTitle('Try It Live');

  useEffect(() => {
    if (scrollY > 50) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  const location = useLocation();

  const markup = location.state?.template || initialMarkup;

  const [code, setCode] = useState(markup.trim());

  return <LiveEdit code={code} scope={scope} onChange={setCode} />;
};

export default TryItLive;
