import React from 'react';
import { LiveError } from 'react-live';
import styled from 'styled-components';
import * as polished from 'polished';

const StyledError = styled(LiveError)`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  border-radius: 6px;
  width: 80%;
  padding: ${polished.rem(8)};
  background: #ff5555;
  color: #f8f8f2;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  white-space: pre-wrap;
  text-align: left;
  font-size: 0.9em;
  font-family: 'Source Code Pro', monospace;
`;

const ErrorHandler = () => <StyledError />;

export default ErrorHandler;
