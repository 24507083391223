import React from 'react';
import { LivePreview } from 'react-live';
import styled from 'styled-components';

import SwitchButton from './switchButton';
import IFrame from './iFrame';

type IContainerProps = {
  hide: boolean;
};

const StyledPreviewContainer = styled.div<IContainerProps>`
  position: relative;
  background: rgb(242, 242, 242);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: ${props => (props.hide ? '0px' : '100%')};
  transition: all 0.1s;

  &:hover {
    .sb-container {
      bottom: 0;
      transition: all 0.3s ease-out;
    }
  }
`;

const StyledPreview = styled(LivePreview)`
  position: relative;
  color: black;
  overflow-y: scroll;
  flex: 1;
  width: 600px;
`;

const SwitchButtonContainer = styled.div`
  position: absolute;
  bottom: -100px;
  right: 70px;
  transition: all 0.3s ease-out;
  height: 80px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

type IProps = {
  mode: string;
  onChange: (mode: string) => void;
  hide: boolean;
};

const Preview = ({ mode, onChange, hide }: IProps) => {
  return (
    <StyledPreviewContainer hide={hide}>
      <IFrame
        frameBorder='0'
        width={mode === 'mobile' ? '320px' : '800px'}
        style={{ position: 'relative', height: '100%' }}
      >
        <StyledPreview />
      </IFrame>
      <SwitchButtonContainer className='sb-container'>
        <SwitchButton mode={mode} onChange={mode => onChange(mode)} />
      </SwitchButtonContainer>
    </StyledPreviewContainer>
  );
};

export default Preview;
