import React from 'react';
import styled from 'styled-components';

import Editor from './editor';

const String = styled.span`
  color: #9ccc65;
`;

const Variable = styled.span`
  color: #ba68c8;
`;

const Function = styled.span`
  color: #fdd835;
`;

const Indent = styled.div`
  padding-left: 30px;
`;

const Tag = styled.span`
  color: #f92672;
`;

const TagOperator = styled.span`
  color: #fff;
`;

const Props = styled.span`
  color: #a6e22e;
`;

const Property = styled.span`
  color: #e6db74;
`;

const Operator = styled.span`
  color: #f92672;
`;

type IProps = {
  width?: string;
  height?: string;
  position?: string;
  numOfLines?: number;
};

const HtmlSnippet = (props: IProps) => {
  return (
    <Editor
      width={props.width}
      height={props.height}
      position={props.position}
      numOfLines={props.numOfLines}
      code={
        <>
          <TagOperator>{`<`}</TagOperator>
          <Tag>{`table`}</Tag>
          <Indent>
            <Props> border</Props>
            <Operator>=</Operator>
            <Property>'0'</Property>
          </Indent>
          <Indent>
            <Props> cellpadding</Props>
            <Operator>=</Operator>
            <Property>'0'</Property>
          </Indent>
          <Indent>
            <Props> cellspacing</Props>
            <Operator>=</Operator>
            <Property>'0'</Property>
          </Indent>
          <Indent>
            <Props> role</Props>
            <Operator>=</Operator>
            <Property>'presentation'</Property>
          </Indent>
          <Indent>
            <Props> style</Props>
            <Operator>=</Operator>
            <Property>'border-collapse:separate; line-height:100%;'</Property>
          </Indent>

          <TagOperator>{`>`}</TagOperator>
          <Indent>
            <TagOperator>{`<`}</TagOperator>
            <Tag>{`tbody`}</Tag>
            <TagOperator>{`>`}</TagOperator>
            <Indent>
              <TagOperator>{`<`}</TagOperator>
              <Tag>{`tr`}</Tag>
              <TagOperator>{`>`}</TagOperator>
              <Indent>
                <TagOperator>{`<`}</TagOperator>
                <Tag>{`tb`}</Tag>
                <Indent>
                  <Props> align</Props>
                  <Operator>=</Operator>
                  <Property>'center'</Property>
                </Indent>
                <Indent>
                  <Props> bgcolor</Props>
                  <Operator>=</Operator>
                  <Property>'#0547f2'</Property>
                </Indent>
                <Indent>
                  <Props> role</Props>
                  <Operator>=</Operator>
                  <Property>'presentation'</Property>
                </Indent>
                <Indent>
                  <Props> style</Props>
                  <Operator>=</Operator>
                  <Property>
                    'border:none;border-radius:3px;cursor:auto;
                    <Indent>
                      mso-padding-alt:10px 25px;background:#0547f2;'
                    </Indent>
                  </Property>
                </Indent>
                <Indent>
                  <Props> valign</Props>
                  <Operator>=</Operator>
                  <Property>'middle'</Property>
                </Indent>
                <TagOperator>{`>`}</TagOperator>
                <Indent>
                  <TagOperator>{`<`}</TagOperator>
                  <Tag>{`p`}</Tag>
                  <Props> style</Props>
                  <Operator>=</Operator>
                  <Property>
                    'display:inline-block; background:#0547f2; color:#ffffff;
                    <Indent>
                      font-family:Ubuntu, Helvetica, Arial, sans-serif;
                    </Indent>
                    <Indent>
                      font-size:13px; font-weight:normal; line-height:120%;
                    </Indent>
                    <Indent>
                      margin:0; text-decoration:none; text-transform:none;
                    </Indent>
                    <Indent>
                      padding:10px25px; mso-padding-alt:0px; border-radius:3px;'
                    </Indent>
                  </Property>
                  <TagOperator>{`>`}</TagOperator>
                  <Indent>I'm a Button</Indent>
                  <TagOperator>{`</`}</TagOperator>
                  <Tag>{`p`}</Tag>
                  <TagOperator>{`>`}</TagOperator>
                </Indent>
                <TagOperator>{`</`}</TagOperator>
                <Tag>{`td`}</Tag>
                <TagOperator>{`>`}</TagOperator>
              </Indent>
              <TagOperator>{`</`}</TagOperator>
              <Tag>{`tr`}</Tag>
              <TagOperator>{`>`}</TagOperator>
            </Indent>
            <TagOperator>{`</`}</TagOperator>
            <Tag>{`tbody`}</Tag>
            <TagOperator>{`>`}</TagOperator>
          </Indent>
          <TagOperator>{`</`}</TagOperator>
          <Tag>{`table`}</Tag>
          <TagOperator>{`>`}</TagOperator>
        </>
      }
    />
  );
};

export default HtmlSnippet;
