import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import useScrollPosition from '@react-hook/window-scroll';
import { Link } from 'react-router-dom';

import GithubStats from '../components/githubStats';
import SplitView from '../components/splitView';
import MobileCodeSplitView from '../components/mobileSplitView';
import InstallSnippet from '../components/codeSnippets/installSnippet';
import { useDocumentTitle } from '../hooks/useDocumentTitle';

const TitleContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-family: 'InterDisplay var', 'Inter', -apple-system, BlinkMacSystemFont,
    'Roboto', 'Open Sans', sans-serif;
  font-size: 6rem;
  font-weight: 900;
  line-height: 1;
  letter-spacing: -0.015em;
  text-align: center;
  color: #86c232;
  margin-top: 0;
  user-select: none;
  text-shadow: 3px 4px 5px #000;

  @media screen and (max-width: 1600px) {
    font-size: 3.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 38px;
  }
`;

const fadeInMouse = keyframes`
	0% {
		opacity: 0; 
	}
  100% {
		opacity: .4; 
	}
`;

type IWheelButton = {
  isHidden: boolean;
};

const WheelButton = styled.button<IWheelButton>`
  opacity: ${props => (props.isHidden ? '0' : '0.4')};
  position: fixed;
  bottom: 60px;
  left: 50%;
  z-index: 1;
  display: block;
  margin-left: -0.3em;
  padding: 0;
  background: transparent;
  color: transparent;
  font-size: 2em;
  cursor: pointer;
  pointer-events: none;
  height: 40px;
  width: 28px;
  border-radius: 20px;
  border: 2px solid white;
  visibility: ${props => (props.isHidden ? 'hidden' : 'visible')};
  animation-name: ${fadeInMouse};
  animation-fill-mode: both;
  animation-delay: 1.2s;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.65, -0.14, 0.52, 0.99);

  @media screen and (max-width: 1600px) {
    bottom: 40px;
    height: 32px;
    width: 24px;
    border-width: 1px;
  }
`;

const mouseWheel = keyframes`
	0% {
		opacity: 1;
		transform: translateY(0); 
	}
	100% {
		opacity: 0;
		transform: translateY(10px); 
	}
`;

const Wheel = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: block;
  margin: -8px auto;
  background: white;
  position: relative;
  animation: ${mouseWheel} 1.8s ease infinite;
`;

const containerCss = css`
  position: relative;
  width: 100%;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    margin: 50px auto;
  }
`;

const titleCss = css`
  color: white;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

const innerContainerCss = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
`;

const DetailsContainer = styled.div`
  ${containerCss}
`;

const DetailsCodeContainer = styled.div`
  ${innerContainerCss}
`;

const DetailsTitle = styled.span`
  ${titleCss}
`;

const StackContainer = styled.div`
  ${containerCss}
`;

const StackImagesContainer = styled.div`
  ${innerContainerCss}
`;

const StackTitle = styled.span`
  ${titleCss}
`;

const StackImage = styled.img`
  height: 100px;
  margin: 0 2rem;

  @media screen and (max-width: 1600px) {
    height: 70px;
    margin: 0.9rem 0;
  }

  @media screen and (max-width: 1024px) {
    height: 70px;
    margin: 0.8rem 0;
  }

  @media screen and (max-width: 600px) {
    height: 50px;
    margin: 0.7rem 0;
  }

  @media screen and (max-width: 480px) {
    height: 40px;
    margin: 0.6rem 0;
  }
`;

const Description = styled.h2`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  color: white;
  text-align: center;
  user-select: none;

  @media screen and (max-width: 1600px) {
    font-size: 22px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14px;
    padding: 0 30px;
  }
`;

const TryItLiveContainer = styled.div`
  width: 100%;
  margin: 100px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    margin: 50px auto;
  }
`;

const StyledLink = styled(Link)`
  background-color: #8ac832;
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  position: relative;
  display: inline-block;
  padding: 0.5em 1.5em;
  font-weight: 600;
  border-radius: 6px;
  transition: color 0.4s cubic-bezier(0.3, 0.1, 0.05, 1),
    background-color 0.8s cubic-bezier(0.3, 0.1, 0.05, 1);

  &:hover {
    background-color: white;
    color: #8ac832;
  }

  @media screen and (max-width: 480px) {
    font-size: 1rem;
    padding: 10px 20px;
  }
`;

const StatsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  @media screen and (max-width: 480px) {
    padding: 25px 0;
    flex-direction: column;
  }
`;

const Welcome = () => {
  const scrollY = useScrollPosition(60 /*fps*/);
  useDocumentTitle('Welcome to react-mailkit');
  return (
    <>
      <TitleContainer>
        <Title>
          where simplicity <br /> meets creativity
        </Title>
        <Description>
          Create awesome and fully responsive emails without stress.
        </Description>
        <TryItLiveContainer>
          <StyledLink to='/try-it-live'>Try It Live</StyledLink>
        </TryItLiveContainer>
      </TitleContainer>
      <SplitView />
      <MobileCodeSplitView />
      <StackContainer>
        <StackTitle>What We Use</StackTitle>
        <StackImagesContainer>
          <StackImage src='mjml.png' alt='mjml' />
          <StackImage src='react.png' alt='react' />
          <StackImage src='typescript.png' alt='typescript' />
        </StackImagesContainer>
      </StackContainer>
      <DetailsContainer>
        <DetailsTitle>How To Install</DetailsTitle>
        <DetailsCodeContainer>
          <InstallSnippet />
        </DetailsCodeContainer>
      </DetailsContainer>
      <StatsContainer>
        <GithubStats />
      </StatsContainer>
      <WheelButton isHidden={scrollY > 40}>
        <Wheel />
      </WheelButton>
    </>
  );
};

export default Welcome;
