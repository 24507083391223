import React, { useLayoutEffect } from 'react';

import styled from 'styled-components';

type IModalProps = {
  isOpen: boolean;
};

const ModalContainer = styled.div<IModalProps>`
  position: fixed;
  z-index: 15;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: font-family Inter, system-ui, sans-serif;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  pointer-events: ${props => (props.isOpen ? 'unset' : 'none')};
  background-color: ${props =>
    props.isOpen ? 'rgba(28, 28, 33, 0.9)' : 'transparent'};
`;

const ModalContent = styled.div`
  width: 800px;
  height: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  overflow: hidden;
`;

const Header = styled.header`
  background-color: rgb(242, 242, 242);
  color: rgb(49, 49, 53);
  font-weight: 400;
  padding: 20px;
  font-size: 25px;
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  overflow-y: scroll;
  padding: 15px;
`;

const CloseButtonOuter = styled.button`
  display: flex;
  flex-shrink: 0;
  border-radius: 50%;
  transition: 0.2s;
  width: 48px;
  height: 48px;
  position: fixed;
  top: calc(0.75 * 1.25em);
  right: calc(0.75 * 1.25em);
  z-index: 10;
  background-color: #000;
  border: none;
  z-index: 10;

  &:hover {
    background-color: #1c1c21;
  }
`;

const CloseIconOuter = styled.svg`
  width: 24px;
  height: 24px;
  display: block;
  margin: auto;
  color: #fff;
`;

type IProps = {
  isOpen: boolean;
  onClose: () => void;
  content: any;
  title: string;
};

const Modal = (props: IProps) => {
  const { isOpen, onClose, title, content } = props;
  useLockBodyScroll();

  return (
    <ModalContainer isOpen={isOpen}>
      <ModalContent>
        <Header>
          <span>{title}</span>
        </Header>
        <Body>{content}</Body>
      </ModalContent>

      <CloseButtonOuter onClick={() => onClose()}>
        <CloseIconOuter viewBox='0 0 24 24'>
          <title>Close modal window</title>
          <g
            fill='none'
            stroke='currentColor'
            strokeMiterlimit='10'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <line x1='3' y1='3' x2='21' y2='21' />
            <line x1='21' y1='3' x2='3' y2='21' />
          </g>
        </CloseIconOuter>
      </CloseButtonOuter>
    </ModalContainer>
  );
};

// Hook
function useLockBodyScroll() {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden';
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, []); // Empty array ensures effect is only run on mount and unmount
}

export default Modal;
