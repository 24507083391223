import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const Title = styled.h2`
  font-family: 'Cardo', serif;
  font-size: 2rem;
  font-style: italic;
  line-height: 1.35;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transform: translateY(calc(100% - 6rem));
  transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 1;

  > * + * {
    margin-top: 1rem;
  }

  &:hover {
    transform: translateY(calc(100% - 4.5rem));

    > *:not(${Title}) {
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1),
        opacity 700ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
`;

type ICardProps = {
  image: string;
};

const Card = styled.div<ICardProps>`
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  width: 100%;
  max-width: 350px;
  height: 500px;
  border-radius: 6px;
  margin: 1rem;
  text-align: center;
  color: whitesmoke;
  background-color: whitesmoke;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
    0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.1);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: ${props => `url(${props.image}.png)`};
    transition: transform calc(700ms * 1.5) cubic-bezier(0.19, 1, 0.22, 1);
    pointer-events: none;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.009) 11.7%,
      hsla(0, 0%, 0%, 0.034) 22.1%,
      hsla(0, 0%, 0%, 0.072) 31.2%,
      hsla(0, 0%, 0%, 0.123) 39.4%,
      hsla(0, 0%, 0%, 0.182) 46.6%,
      hsla(0, 0%, 0%, 0.249) 53.1%,
      hsla(0, 0%, 0%, 0.32) 58.9%,
      hsla(0, 0%, 0%, 0.394) 64.3%,
      hsla(0, 0%, 0%, 0.468) 69.3%,
      hsla(0, 0%, 0%, 0.54) 74.1%,
      hsla(0, 0%, 0%, 0.607) 78.8%,
      hsla(0, 0%, 0%, 0.668) 83.6%,
      hsla(0, 0%, 0%, 0.721) 88.7%,
      hsla(0, 0%, 0%, 0.762) 94.1%,
      hsla(0, 0%, 0%, 0.79) 100%
    );
    transform: translateY(-50%);
    transition: transform calc(700ms * 2) cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover,
  &:focus-within {
    align-items: center;
  }

  &:hover:before,
  &:focus-within:before {
    transform: translateY(-4%);
  }

  &:hover:after {
    background-image: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0.123) 0%,
      hsla(0, 0%, 0%, 0.182) 11.7%,
      hsla(0, 0%, 0%, 0.249) 22.1%,
      hsla(0, 0%, 0%, 0.32) 31.2%,
      hsla(0, 0%, 0%, 0.394) 39.4%,
      hsla(0, 0%, 0%, 0.468) 46.6%,
      hsla(0, 0%, 0%, 0.54) 53.1%,
      hsla(0, 0%, 0%, 0.607) 58.9%,
      hsla(0, 0%, 0%, 0.668) 64.3%,
      hsla(0, 0%, 0%, 0.721) 69.3%,
      hsla(0, 0%, 0%, 0.762) 74.1%,
      hsla(0, 0%, 0%, 0.79) 78.8%,
      hsla(0, 0%, 0%, 0.851) 83.6%,
      hsla(0, 0%, 0%, 0.901) 88.7%,
      hsla(0, 0%, 0%, 0.951) 94.1%,
      hsla(0, 0%, 0%, 0.981) 100%
    );
  }

  &:hover:after,
  &:focus-within:after {
    transform: translateY(-50%);
  }

  &:hover ${Content}, &:focus-within ${Content} {
    transform: translateY(0);
  }

  &:hover ${Content} > *:not(${Title}),
  &:focus-within ${Content} > *:not(${Title}) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: calc(700ms / 8);
  }

  &:focus-within:before,
  &:focus-within:after,
  &:focus-within ${Content}, &:focus-within ${Content} > *:not(${Title}) {
    transition-duration: 0s;
  }
`;

const buttonsStyle = css`
  width: 100%;
  background-color: #8ac832;
  border: none;
  color: white;
  text-decoration: none;
  font-size: 18px;
  position: relative;
  display: inline-block;
  padding: 10px;
  font-weight: 600;
  border-radius: 25px;
  transition: color 0.4s cubic-bezier(0.3, 0.1, 0.05, 1),
    background-color 0.8s cubic-bezier(0.3, 0.1, 0.05, 1);

  &:hover {
    background-color: white;
    color: #8ac832;
  }
`;

const StyledButton = styled.button`
  ${buttonsStyle}

  max-width: 170px;
`;

const StyledLink = styled(Link)`
  ${buttonsStyle}

  max-width: 150px;
`;

type IProps = {
  template: string;
  title: string;
  image: string;
  onPreviewClick: () => void;
};

const TemplateCard = ({ template, title, image, onPreviewClick }: IProps) => (
  <Card image={image}>
    <Content>
      <Title>{title}</Title>
      <StyledButton onClick={() => onPreviewClick()}>Preview</StyledButton>
      <StyledLink
        to={{
          pathname: '/try-it-live',
          state: {
            template
          }
        }}
      >
        Try It Live
      </StyledLink>
    </Content>
  </Card>
);

export default TemplateCard;
